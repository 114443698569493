<template>
  <v-card>
    <v-row class="pl-3">
      <v-col cols="3">
        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs"
              append-icon="mdi-calendar"
              @click:append="on.click"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable locale="th-th" @input="menu = false"> </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs2"
              append-icon="mdi-calendar"
              @click:append="on.click"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append-outer>
                <v-btn small color="primary" @click="handleSelectDate">เรียกดู</v-btn>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="date2" no-title scrollable locale="th-th" @input="menu2 = false"> </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <!--all_bill -->
      <v-col md="6" sm="6" cols="12">
        <v-card class="bg-gradient" dark>
          <v-card-title>
            <v-icon size="40" color="#fff">mdi-ballot-outline </v-icon>
            <div class="pl-2" style="color: #aaff6d">จำนวนบิล</div>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold text-2xl text-center text--white">{{ today_total.all_bill }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- total_all -->
      <v-col md="6" sm="6" cols="12">
        <v-card class="bg-gradient" dark>
          <v-card-title>
            <v-icon size="40" color="#fff">mdi-currency-usd </v-icon>
            <div class="pl-2" style="color: #aaff6d">ยอดขายรวม</div>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold text-2xl text-center text--white">
              ฿ {{ formatInt(today_total.total_all) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!--total_pay_all -->
      <v-col md="4" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>ยอดชำระ</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#47B881" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-cash-multiple </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(today_total.total_pay_all) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- total_remain -->
      <v-col md="4" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>ยอดค้างชำระ</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#FDBC15" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-account-supervisor-circle </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(today_total.total_remain) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- total_cancel -->
      <v-col md="4" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>ยกเลิกการชำระ</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#9C0E05" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-chart-line-variant </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">{{ formatInt(today_total.total_cancel) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <div class="font-weight-bold pl-3 pt-5">รายการชำระวันนี้</div>
    <v-row class="pa-3">
      <v-col>
        <vue-excel-xlsx :data="filteredItems" :columns="columnsXcel" :filename="filename" :sheetname="'Sheet1'">
          <v-btn small color="success" class="text-capitalize">
            <v-icon small class="mr-2"> mdi-microsoft-excel</v-icon> Excel
          </v-btn>
        </vue-excel-xlsx>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-select v-model="selected" :items="list_selected" item-value="value" item-text="label" dense solo> </v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      disable-sort
    >
      <template v-slot:no-data>
        <span class="text-center">ยังไม่มีข้อมูล</span>
      </template>

      <template v-slot:[`item.receipt_date`]="{ item }">
        <v-row class="flex-nowrap items-center align-center pl-2">
          <div>
            {{ formatDate(item.receipt_date) }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.order_num`]="{ item }">
        <v-row class="flex-nowrap items-center align-center pl-2">
          <v-chip @click="handleClick(item)" color="primary">
            {{ item.order_num }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:[`item.receipt_code`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="font-weight-bold text-truncate pl-2" style="max-width: 180px">
            {{ item.receipt_code }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="text-truncate pl-2" style="max-width: 180px">{{ item.user.fname }} {{ item.user.lname }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2">
            {{ item.user.phone }}
          </div>
        </v-row>
      </template>

      <template v-slot:[`item.total_all`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.total_all) }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.total_pay`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div v-if="item.status != 5" class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.total_pay) }}</div>
          <div v-else class="pl-2 font-weight-bold text--red">฿ - {{ formatInt(item.total_pay) }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.remain`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.remain) }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row class="flex-nowrap items-center align-center pl-2">
          <div v-if="item.status == 1" class="warning--text">
            <v-chip class="ma-2" color="#FEC400" label text-color="white" small>
              <v-icon left small> mdi-alert-circle-outline </v-icon>
              ชำระครั้งที่ {{ item.num_pay }}
            </v-chip>
          </div>
          <div v-else-if="item.status == 2" class="success--text">
            <v-chip class="ma-2" color="#43C50699" label text-color="white" small>
              <v-icon left small> mdi-check </v-icon>
              จ่ายครบ
            </v-chip>
          </div>
          <div v-else class="error--text">
            <v-chip class="ma-2" color="#9C0E05" label text-color="white" small>
              <v-icon left small> mdi-arrow-u-right-top </v-icon>
              ยกเลิก
            </v-chip>
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row class="pl-2 items-center align-center">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleDetail(item.id)">
                <v-list-item-title> <v-icon small> mdi-file-document </v-icon> รายละเอียด</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="handleEdit(item.id)" v-show="item.status != 5">
                <v-list-item-title class="warning--text">
                  <v-icon small color="warning"> mdi-pencil </v-icon> แก้ไข</v-list-item-title
                >
              </v-list-item> -->
              <!-- <v-list-item v-show="item.status != 2 && item.status != 5">
                <v-list-item-title class="success--text">
                  <v-icon small color="success"> mdi-cash-multiple </v-icon> ชำระเงิน</v-list-item-title
                >
              </v-list-item> -->
              <v-list-item @click="showCancel(item)" v-show="item.status != 5">
                <v-list-item-title class="error--text">
                  <v-icon small color="error"> mdi-arrow-u-right-top </v-icon> ยกเลิก</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-flex flex-row-reverse pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </div>
    <!-- dialog cancel -->
    <v-dialog v-model="dialogCancel" max-width="330px">
      <v-card>
        <v-card-title> <div class="text-left py-3" style="font-size: 22px">ยกเลิกการชำระ ?</div> </v-card-title>
        <v-card-subtitle>
          <div class="text-left pb-3">กรุณาระบุรหัสผ่านของคุณเพื่อยืนยันตัวตน ในการยกเลิกรายการชำระนี้</div>
          <v-textarea outlined label="เหตุผลในการยกเลิก" v-model="note_text"></v-textarea>
        </v-card-subtitle>
        <v-card-subtitle> <div class="error--text text-sm">รหัสผ่านของคุณ</div> </v-card-subtitle>
        <v-card-subtitle>
          <v-text-field
            v-model="password"
            label="password"
            solo
            :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showEye ? 'text' : 'password'"
            @click:append="showEye = !showEye"
          >
          </v-text-field>
          <div class="error--text">{{ resultCheckPass == 'error' ? 'รหัสผ่านไม่ถูกต้อง' : '' }}</div>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogCancel = false">ยกเลิก</v-btn>
          <v-btn color="primary" class="primary" @click="checkPassAdmin">ยืนยัน</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import FormatNumber from '@/helper/function'
import moment from 'moment'
export default {
  data() {
    return {
      showEye: false,
      menu: false,
      menu2: false,
      listOrder: [],
      today_total: '',
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'วันที่', align: 'left', value: 'receipt_date' },
        { text: 'รหัสออเดอร์', align: 'left', value: 'order_num' },
        { text: 'รหัสคำสั่งซื้อ', align: 'left', value: 'receipt_code' },
        { text: 'ลูกค้า', align: 'left', value: 'user' },
        { text: 'เบอร์โทร', align: 'left', value: 'phone' },
        { text: 'ยอดรวม', align: 'left', value: 'total_all' },
        { text: 'ชำระรวม', align: 'left', value: 'total_pay' },
        { text: 'สถานะ', align: 'left', value: 'status' },
        { text: 'ค้างชำระ', align: 'left', value: 'remain' },
        { text: 'จัดการ', align: 'left', value: 'action' },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),

      columnsXcel: [
        {
          label: 'รหัสคำสั่งซื้อ',
          field: 'receipt_code',
        },
        {
          label: 'รหัสคำสั่งซื้อ',
          field: 'order_num',
        },
        {
          label: 'ลูกค้า',
          field: 'user',
          dataFormat: this.userFormat,
        },
        {
          label: 'เบอร์โทร',
          field: 'user',
          dataFormat: this.userPhone,
        },
        {
          label: 'วันที่',
          field: 'receipt_date',
        },
        {
          label: 'ชำระรวม',
          field: 'total_pay',
        },
        {
          label: 'ยอดรวม',
          field: 'total_all',
        },
        {
          label: 'ค้างชำระ',
          field: 'remain',
        },
        {
          label: 'สถานะ',
          field: 'status',
          dataFormat: this.userStatus,
        },
      ],
      id_cancel: '',
      receipt_code: '',
      note_text: '',
      dialogCancel: false,
      password: '',
      resultCheckPass: '',
      selected: '',
      list_selected: [
        { value: '', label: 'ทั้งหมด' },
        { value: '1', label: 'ค้างชำระ' },
        { value: '2', label: 'จ่ายครบ' },
        { value: '5', label: 'ยกเลิกบิล' },
      ],
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.formatDate(this.date) : ''
    },
    computedDateFormattedMomentjs2() {
      return this.date2 ? this.formatDate(this.date2) : ''
    },
    filename() {
      return 'รายการบิล' + this.formatDate(this.date) + '-' + this.formatDate(this.date2)
    },
    filteredItems() {
      return this.listOrder.filter(i => {
        return !this.selected || i.status == this.selected
      })
    },
  },

  mounted() {
    this.getListOrder(this.date + '/' + this.date2)
  },
  methods: {
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    formatDate(date) {
      moment.locale('th')
      return moment(date)
        .add(543, 'year')
        .format('DD MMMM YYYY')
    },
    handleSelectDate() {
      this.getListOrder(this.date + '/' + this.date2)
    },
    getListOrder(date) {
      this.$store.dispatch('getListOrder', date).then(res => {
        console.log(res)
        this.listOrder = res.list_order
        this.today_total = res.today_total
      })
    },
    showCancel(item) {
      this.id_cancel = item.id
      this.receipt_code = item.receipt_code
      console.log(this.receipt_code, 'this.receipt_code')
      this.dialogCancel = true
    },
    handleDetail(id) {
      this.$router.push('/list-order-daily/billing/' + id)
    },
    cancelItem() {
      var data = {
        id: this.id_cancel,
        note_text: this.note_text,
      }
      this.$store.dispatch('cancelOrder', data).then(res => {
        console.log(res, 'cancelItem')
        this.getListOrder(this.date + '/' + this.date2)
        this.dialogCancel = false
        this.password = ''
        this.note_text = ''
      })
    },
    checkPassAdmin() {
      var data = {
        password: this.password,
      }
      this.$store.dispatch('checkPassword', data).then(res => {
        console.log(res.message, 'checkPassword')
        this.resultCheckPass = res.message
        if (this.resultCheckPass != 'error') {
          this.$store.dispatch('postCancelCommission', this.receipt_code).then(response => {
            if ((response = 'success')) {
              this.cancelItem()
            }
          })
        }
      })
    },
    userFormat(value) {
      return value.fname + ' ' + value.lname
    },
    userPhone(value) {
      return value.phone
    },
    userStatus(value) {
      if (value == 1) {
        return 'ค้างชำระ'
      } else if (value == 2) {
        return 'จ่ายครบ'
      } else {
        return 'ยกเลิกบิล'
      }
    },
    handleEdit(id) {
      this.$store.dispatch('getReceiptOrderById', id).then(order => {
        console.log(order)
        const order_list = []
        order.order_list.map(res => {
          console.log(res)
          if (res.remain != 0 && res.order_type != 'product') {
            Object.assign(res, { price_pay: parseInt(res.remain) })
            order_list.push(res)
          }
        })
        const data = {
          orders: order_list,
          total: order.remain,
          total_price: order.remain,
          data: order,
        }
        this.$store.commit('SET_ORDER', data)
        this.$router.push(`/openbill/detail/${order.order_id}`)
      })
    },
    handleClick(res) {
      console.log(res)
      this.$router.push(`/orderList/${res.order_id}`)
    },
  },
}
</script>
<style scoped>
.bg-gradient {
  background: linear-gradient(0deg, #033654 0%, #0066a3 100%, #033654 100%);
}
</style>
